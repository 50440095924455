var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('div', {
          staticClass: "list"
        }, _vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")])]), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "value": _vm.productPropertyMap[item.productProperty] || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "value": item.productName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "调出仓库",
              "required": "",
              "is-link": ""
            },
            on: {
              "click": function ($event) {
                return _vm.onOutSpaceListShow(index);
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('div', {
                  staticClass: "pickerText",
                  class: {
                    disabled: item.outSpaceName === ''
                  }
                }, [_vm._v(_vm._s(item.outSpaceName || '请选择') + " ")])];
              },
              proxy: true
            }], null, true)
          }), _c('van-cell', {
            attrs: {
              "title": "资产编号"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [item.productProperty === 3 && item.guDingList && item.guDingList.length > 0 ? _c('div', _vm._l(item.guDingList, function (obj, j) {
                  return _c('div', [_vm._v(_vm._s(obj.productNo))]);
                }), 0) : item.productProperty !== 3 && item.productNo ? _c('div', [_vm._v(_vm._s(item.productNo))]) : _c('div', [_vm._v("-")])];
              },
              proxy: true
            }], null, true)
          }), item.productProperty !== 3 ? _c('van-cell', {
            attrs: {
              "title": "调出价格/元",
              "value": item.outPrice || '-'
            }
          }) : _vm._e(), item.productProperty !== 3 ? _c('van-cell', {
            attrs: {
              "title": "库存数量",
              "value": item.stockCount || '-'
            }
          }) : _vm._e(), _c('van-cell', {
            attrs: {
              "title": "调拨申请数量",
              "value": item.applicationNum || '-'
            }
          }), item.productProperty === 3 ? _c('van-cell', {
            attrs: {
              "title": "调出数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "integer": "",
                    "disabled": ""
                  },
                  model: {
                    value: item.outNum,
                    callback: function ($$v) {
                      _vm.$set(item, "outNum", $$v);
                    },
                    expression: "item.outNum"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }) : _c('van-cell', {
            attrs: {
              "title": "调出数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "integer": "",
                    "min": 0,
                    "max": item.stockCount || 0
                  },
                  model: {
                    value: item.outNum,
                    callback: function ($$v) {
                      _vm.$set(item, "outNum", $$v);
                    },
                    expression: "item.outNum"
                  }
                })];
              },
              proxy: true
            }], null, true)
          })], 1)], 1);
        }), 0), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调出管理员",
            "value": _vm.outKeeper || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "调出时间",
            "value": _vm.outTime || '-'
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调入申请员",
            "value": _vm.inApplicant || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "调入项目",
            "value": _vm.inProjectName || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "调拨总金额",
            "value": _vm.totalPrice || '-'
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('div', {
                staticClass: "cell-group-title"
              }, [_c('span', [_vm._v("其他信息")])])];
            },
            proxy: true
          }])
        }, [_c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "label": "备注",
            "rows": "3",
            "autosize": "",
            "input-align": "left"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onConfirm
          }
        }, [_vm._v("确认调拨")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    style: {
      height: '50vh'
    },
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.outSpaceListShow,
      callback: function ($$v) {
        _vm.outSpaceListShow = $$v;
      },
      expression: "outSpaceListShow"
    }
  }, [_c('div', {
    staticClass: "productListPanel"
  }, [_c('div', {
    staticClass: "van-picker__toolbar"
  }, [_c('button', {
    staticClass: "van-picker__cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onProductCancel
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "van-ellipsis van-picker__title"
  }), _c('button', {
    staticClass: "van-picker__confirm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onProductConfirm
    }
  }, [_vm._v("确认")])]), _c('div', {
    staticClass: "productListResult"
  }, [_vm._l(_vm.outSpaceList, function (item, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "clickable": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onProductCheck(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "productListResultRow"
          }, [_c('span', {
            staticClass: "c1"
          }, [_vm._v(_vm._s(item.spaceName))]), _vm.currentProductProperty == 3 ? _c('span', {
            staticClass: "c2"
          }, [_vm._v(_vm._s(item.productNo))]) : _vm._e(), _vm.currentProductProperty == 3 ? _c('span', {
            staticClass: "c3"
          }, [_vm._v("￥" + _vm._s(item.actPrice))]) : _c('span', {
            staticClass: "c3"
          }, [_vm._v(_vm._s(item.count))]), _c('van-checkbox', {
            attrs: {
              "value": _vm.checks[item.id]
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    });
  }), !_vm.outSpaceList.length ? _c('van-empty', {
    attrs: {
      "description": "无数据"
    }
  }) : _vm._e()], 2), _c('van-pagination', {
    attrs: {
      "total-items": _vm.productListSearchTotal,
      "items-per-page": _vm.productListSearchPageSize
    },
    on: {
      "change": _vm.onOutSpaceListPage
    },
    model: {
      value: _vm.productListSearchPageNum,
      callback: function ($$v) {
        _vm.productListSearchPageNum = $$v;
      },
      expression: "productListSearchPageNum"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };