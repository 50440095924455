import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import moment from 'moment';
import Vue from 'vue';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsDispatchOutDetail',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo']),
    totalPrice() {
      let sum = 0;
      this.productList.forEach(product => {
        try {
          if (product.productProperty === 3 && product.guDingList && product.guDingList.length) {
            for (let gu in product.guDingList) {
              sum += Math.round(parseFloat(product.outPrice || 0) * 100);
            }
          } else {
            let price = Math.round(parseFloat(product.outPrice || 0) * 100);
            sum += price * (product.outNum || 0);
          }
        } catch (e) {}
      });
      return sum / 100;
    }
  },
  data() {
    return {
      from: '',
      allocateApplyId: '',
      inApplicant: '',
      inProjectId: '',
      inProjectName: '',
      productList: [],
      outProjectId: '',
      outProjectName: '',
      outKeeper: '',
      outTime: '',
      remark: '',
      productPropertyMap,
      outSpaceListShow: false,
      outSpaceList: [],
      currentProductIndex: -1,
      currentProductProperty: 0,
      productListSearchPageNum: 1,
      productListSearchPageSize: 10,
      productListSearchTotal: 0,
      checks: {}
    };
  },
  created() {
    this.allocateApplyId = this.$route.query.allocateApplyId;
    this.outTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getOutAllocateApplyDetail(this.allocateApplyId).then(res => {
        let item = res.data.data.result;
        this.inApplicant = item.inApplicant;
        this.inProjectId = item.inProjectId;
        this.inProjectName = item.inProjectName;
        this.outProjectId = item.outProjectId;
        this.outProjectName = item.outProjectName;
        this.outKeeper = item.outKeeper;
        for (let product of item.productList) {
          product.outNum = product.applicationNum || 0;
          product.stockCount = 0;
          product.guDingList = [];
        }
        this.productList = item.productList;
      });
    },
    onOutSpaceListShow(index) {
      this.currentProductIndex = index;
      this.currentProductProperty = this.productList[index].productProperty;
      this.outSpaceList = [];
      this.productListSearchPageNum = 0;
      this.productListSearchTotal = 0;
      this.checks = this.productList[index].checks || {};
      this.outSpaceListShow = true;
      this.onOutSpaceListPage();
    },
    onOutSpaceListPage() {
      let index = this.currentProductIndex;
      let productId = this.productList[index].productId;
      assetsApi.getStockProductList({
        projectId: this.projectInfo.dcProjectId,
        productId,
        minCount: 0,
        pageNum: this.productListSearchPageNum,
        pageSize: this.productListSearchPageSize
      }).then(res => {
        let list = res.data.data || [];
        this.productListSearchTotal = res.data.count;
        this.outSpaceList = list;
      });
    },
    onProductCheck(item) {
      let obj = {
        productNo: item.productNo,
        outPrice: item.actPrice,
        stockCount: item.count,
        spaceFullCode: item.spaceFullCode,
        outSpaceName: item.spaceName,
        stockId: item.id
      };
      if (this.currentProductProperty === 3) {
        let checked = this.checks[item.id];
        if (checked) {
          Vue.delete(this.checks, item.id);
        } else {
          Vue.set(this.checks, item.id, obj);
        }
      } else {
        let checked = this.checks[item.id];
        if (!checked) {
          this.checks = {
            [item.id]: obj
          };
        }
      }
    },
    onProductCancel() {
      this.outSpaceListShow = false;
      this.checks = {};
    },
    onProductConfirm() {
      this.outSpaceListShow = false;
      this.productList[this.currentProductIndex].guDingList = [];
      this.productList[this.currentProductIndex].checks = this.checks;
      for (let key in this.checks) {
        let obj = this.checks[key];
        if (obj) {
          this.productList[this.currentProductIndex].stockId = obj.stockId;
          this.productList[this.currentProductIndex].spaceFullCode = obj.spaceFullCode;
          this.productList[this.currentProductIndex].outSpaceName = obj.outSpaceName;
          this.productList[this.currentProductIndex].outPrice = obj.outPrice;
          this.productList[this.currentProductIndex].stockCount = obj.stockCount;
          if (this.currentProductProperty === 3) {
            this.productList[this.currentProductIndex].guDingList.push(obj);
          }
        }
      }
      if (this.currentProductProperty === 3) {
        this.productList[this.currentProductIndex].outNum = this.productList[this.currentProductIndex].guDingList.length;
      }
      this.productList = [...this.productList];
    },
    onConfirm() {
      this.$dialog.confirm({
        message: '请确认是否同意该调拨出库？'
      }).then(() => {
        let productList = [];
        for (let item of this.productList) {
          let product = {
            ...item
          };
          delete product.checks;
          productList.push(product);
        }
        assetsApi.addOutAllocateApply({
          projectId: this.projectInfo.dcProjectId,
          allocateApplyId: this.allocateApplyId,
          inApplicant: this.inApplicant,
          inProjectId: this.inProjectId,
          inProjectName: this.inProjectName,
          outKeeper: this.outKeeper,
          totalPrice: this.totalPrice,
          remark: this.remark,
          productList
        }).then(res => {
          this.$router.replace({
            name: 'AssetsDispatchOutSuccess'
          });
        });
      }).catch(() => {
        // on cancel
      });
    }
  }
};